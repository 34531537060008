import { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Play from "./page/play/Play";
const SoftwarePage = lazy(() => import("./page/apps/Software.page"));
const Footer = lazy(() => import("./components/layout/Footer"));
const Spinner = lazy(() => import("./components/molicules/Spinner"));
const AppsPage = lazy(() => import("./page/apps/AppsPage"));
const Reports = lazy(() => import("./page/Report"));
const ReportCreate = lazy(() => import("./page/ReportCreate"));
const Home = lazy(() => import("./page/Home"));
const Header = lazy(() => import("./components/layout/Header"));
const LiveTvs = lazy(() => import("./page/LiveTV"));
const Search = lazy(() => import("./page/Search"));
const SignIn = lazy(() => import("./page/Signin"));
const NotFound = lazy(() => import("./page/error/NotFound"));
const Movie = lazy(() => import("./page/movies/MovieView"));
const Movies = lazy(() => import("./page/movies/Movies"));
const SeasonView = lazy(() => import("./page/tv-series/SeasonView"));
const TVseries = lazy(() => import("./page/tv-series/TVseries"));
const TVview = lazy(() => import("./page/tv-series/TVview"));
const Games = lazy(() => import("./page/apps/Games.page"));

function App() {
  return (
    <>
      <Suspense fallback={<div className="h-screen flex justify-center items-center"><Spinner /></div>}>
        <Header />
        <main className="min-h-screen p-0 m-0">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/movies" element={<Movies />} />
            <Route path="/movies/:id" element={<Movie />} />
            <Route path="/tv-shows" element={<TVseries />} />
            <Route path="/tv-shows/:id" element={<TVview />} />
            <Route path="/tv-shows/:id/:season" element={<SeasonView />} />
            <Route path="/live-tv" element={<LiveTvs />} />
            <Route path="/search" element={<Search />} />
            <Route path="/play" element={<Play />} />
            <Route path="/apps" element={<AppsPage />} />
            <Route path="/games" element={<Games />} />
            <Route path="/softwares" element={<SoftwarePage />} />
            <Route path="/report">
              <Route index element={<Reports />} />
              <Route path="create" element={<ReportCreate />} />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </main>
        <Footer />
        <ToastContainer
          autoClose={5000}
          draggable
          closeOnClick
          pauseOnHover
          theme="colored"
          position="bottom-center"
        />
      </Suspense>
    </>
  );
}

export default App;
