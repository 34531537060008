import axios from "axios";
import { clsx } from "clsx";
import moment from "moment";
import { twMerge } from "tailwind-merge";
import SITE_CONFIG from "../config/site.config";
import { SERVER_URL } from "../config/api.config";

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export function errorMessage(error) {
  return `Error : ${error?.response?.data?.message || error?.request?.message || error.message
    }`;
}

export function isTouchDevice() {
  try {
    return (
      "ontouchstart" in window ||
      navigator?.maxTouchPoints > 0 ||
      navigator?.msMaxTouchPoints > 0
    );
  } catch {
    return true;
  }
}

export function secondsToTimeFormat(seconds) {
  const duration = moment.duration(seconds, "seconds");
  return moment.utc(duration.asMilliseconds()).format("HH:mm:ss");
}

export const isFullScreen = () =>
  document?.fullscreenElement ||
  document?.webkitFullscreenElement ||
  document?.mozFullScreenElement ||
  document?.msFullscreenElement;

export const requestFullScreen = () => {
  const element = window?.document?.documentElement;
  if (element?.requestFullscreen) {
    element?.requestFullscreen().catch(logError);
  } else if (element?.mozRequestFullScreen) {
    element.mozRequestFullScreen().catch(logError);
  } else if (element?.webkitRequestFullscreen) {
    element?.webkitRequestFullscreen().catch(logError);
  } else if (element?.msRequestFullscreen) {
    element?.msRequestFullscreen().catch(logError);
  }
};

export const exitFullScreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen();
  }
};

export function logError(error) {
  // eslint-disable-next-line no-undef
  if (process.env.NODE_ENV === "development") {
    console.info("NO ERROR LOG API WILL BE CALLED DURING DEVELOPMENT TIME.");
    return;
  }
  const id =
    "AKfycbyzr_eseZjFT7t0dqiKaXdKyPnZbBTgWLntbawghOlYhKZSRUZKpO54hz3e-pAjpw3Pcg";
  axios(
    `https://script.google.com/macros/s/${id}/exec?text=${error?.message}&device=${window?.navigator?.userAgent}&serverIP=${window.location.origin}&serverName=${SITE_CONFIG.name}&errorName=${error?.name}`
  )
    .then(() => console.log("Error LOGGED"))
    .catch((error) => {
      console.log(error.message);
    });
}

/**
 * 
 * @param {*} id 
 * @param {'MOVIES'|'TV SERIES'|'live'} type 
 * @returns 
 */
export function play_url(id, type = "MOVIES" | "TV SERIES" | 'live') {
  let play_url = "/not-found";
  if (type === "MOVIES") {
    play_url = `/play?type=movies&id=${id}&start=0`;
  } else if (type === "TV SERIES") {
    play_url = `/tv-shows/${id}`;
  } else if (type === "live") {
    play_url = `/play?type=live&id=${id}`;
  }
  return play_url;
}

/**
 * 
 * @param {*} id 
 * @param {'MOVIES'|'TV SERIES'|'live'} type 
 * @returns 
 */
export function single_page_url(id, type = "MOVIES" | "TV SERIES" | 'live') {
  let url = "/not-found";
  if (type === "MOVIES") {
    url = `/movies/${id}`;
  } else if (type === "TV SERIES") {
    url = `/tv-shows/${id}`;
  } else if (type === "live") {
    url = `/play?type=live&id=${id}`;
  }
  return url;
}
/**
 * 
 * @param {url} url - moviedom `MovieWatchLink` or episode's `watchlink`
 */
export function moviedomVideoUrl(url) {
  if (!url) return '';
  return String(url || '').startsWith('../../..') ? String(url || '').replace('../../..',SERVER_URL) : url;
}