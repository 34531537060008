// import "core-js";
import React from "react";
import ReactDOM from "react-dom/client";
import { ErrorBoundary } from "react-error-boundary";
import { BrowserRouter, HashRouter } from "react-router-dom";
import App from "./App.jsx";
import ErrorPage from "./page/error/ErrorPage.jsx";
import "./scss/global.scss";


ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <ErrorBoundary FallbackComponent={ErrorPage} onReset={console.log}>
      <HashRouter>
        <App />
      </HashRouter>
    </ErrorBoundary>
  </React.StrictMode>
);
