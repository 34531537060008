import axios from "axios";

export const SERVER_URL = "http://103.179.128.246";
export const API = axios.create({
  baseURL: `${SERVER_URL}/api/v1/`
});

export const LiveTvApi = axios.create({
  baseURL : 'https://api-fmnetwork.defineatoz.com/api/ip-tv'
})

export const LocalHttp = axios.create({
  baseURL: '/static-api'
})

export const TMDB = axios.create({
  baseURL: "https://api.themoviedb.org/3/",
  params: {
    api_key: "8a16270850e8369e5068c94e617be710",
  },
});
