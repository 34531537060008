import { Swiper, SwiperSlide } from "swiper/react";
import { getImagePath } from "../../../../lib/images";
import { EffectFade,Autoplay } from "swiper/modules";
import {
  HandThumbUpIcon,
  InformationCircleIcon,
  PlayIcon,
  StarIcon,
} from "@heroicons/react/24/solid";
import CircleRating from "../../../atoms/CricleRating";
import Button from "../../../atoms/Button";
import React, { forwardRef } from "react";
const motion = React.lazy(() => import('framer-motion').then(module => ({ default: module.motion })));
import { Link, useNavigate } from "react-router-dom";
import { play_url, single_page_url } from "../../../../lib/utils";
import "swiper/css";
import "swiper/css/free-mode";
import { cx } from "class-variance-authority";

const variants = {
  enter: {
    opacity: 0,
    y: -100,
    transition: { duration: 0.5 },
  },
  active: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.5 },
  },
  exit: {
    opacity: 0,
    y: 100,
    transition: { duration: 0.5 },
  },
};

const MainSlider = forwardRef(function C({ content = [] }, ref) {
  const navigate = useNavigate();
  if (!Array.isArray(content) || !content?.length) return null;
  return (
    <Swiper
      ref={ref}
      effect="fade"
      breakpoints={{
        0: {
          autoplay: {
            delay:2500,
            pauseOnMouseEnter:false,
            disableOnInteraction:false,
          },
          allowTouchMove:true
        },
        720: {
          autoplay: false,
          allowTouchMove:false
        },
      }}
      modules={[EffectFade,Autoplay]}
      className="banner-premium-main-slider"
    >
      {content.map((item) => {
        const backdrop = getImagePath(
          item.id,
          item?.Library?.type == "MOVIES" ? "movie" : "tv",
          "backdrop",
          1980
        );
        const logo = getImagePath(
          item.id,
          item?.Library?.type == "MOVIES" ? "movie" : "tv",
          "logo",
          400
        );
        const rating_out_of_5 = (item?.online_rating * 5) / 10;
        function goToPlay() {
          navigate(play_url(item?.id, item?.Library?.type));
        }
        function goToDetails() {
          navigate(single_page_url(item?.id, item?.Library?.type));
        }

        return (
          <SwiperSlide key={item.id}>
            {({ isActive }) => (
              <div className="relative bg-dark">
                {/* START : MEDIA CONTENT */}
                <div className="slider-premium_media relative min-h-[80lvh] md:min-h-lvh after:content-[''] after:absolute after:top-0 after:left-0 after:size-full after:z-10 after:bg-gradient-to-t md:after:bg-gradient-to-tr md:after:from-5% md:after:to-70% after:from-dark after:to-transparent before:content-[''] before:hidden md:before:block before:absolute before:top-0 before:left-0 before:size-full before:z-10 before:bg-gradient-to-t md:before:bg-gradient-to-t before:from-dark before:from-5% before:to-transparent before:to-35%">
                  <motion.img
                    initial={{ scale: 1.5, opacity: 0 }}
                    animate={{
                      scale: isActive ? 1 : 1.5,
                      opacity: isActive ? 1 : 0,
                    }}
                    transition={{
                      type: "tween",
                      ease: "easeOut",
                      duration: 1,
                    }}
                    className="absolute top-0 left-0 size-full object-cover"
                    src={backdrop}
                    alt={item?.title}
                  />
                </div>
                {/* END : MEDIA CONTENT */}
                {/* START : CONTENT */}
                <motion.div
                  initial="enter"
                  animate={isActive ? "active" : "exit"}
                  variants={variants}
                  className={cx(
                    "absolute top-0 left-0 size-full flex items-end",
                    isActive ? "z-50" : ""
                  )}
                >
                  <div className="container">
                    <div className="hidden grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 mb-4">
                      <div className="grid grid-cols-2 gap-4">
                        {Array.from({ length: 4 }).map((_, i) => (
                          <div key={i}>
                            <img
                              src={`https://image.tmdb.org/t/p/original/iAhxbQ9uXo8B0cMvcPEqIuQY36Y.jpg`}
                              className="w-full rounded-lg"
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className=" pb-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                      <div>
                        {/* RATING */}
                        <div className="flex gap-4 flex-wrap items-end mb-2">
                          <div className="flex flex-col-reverse gap-2 rounded-full p-1 bg-white/5 pt-4 justify-center items-center border border-white/25 border-dashed backdrop-blur-md backdrop-saturate-150">
                            <CircleRating
                              inView={isActive}
                              size={36}
                              value={Math.round(
                                (item?.online_rating || 0) * 10
                              )}
                            />
                            <span className="flex flex-col items-center font-semibold tracking-widest">
                              <span>T</span>
                              <span>M</span>
                              <span>D</span>
                              <span>B</span>
                            </span>
                          </div>
                          <div className="flex-grow">
                            <img
                              className="max-w-60 max-h-28 object-contain mb-4"
                              src={logo}
                            />
                            <h3 className="tracking-wider mb-2">
                              {item?.title}
                              <span className="text-lg ml-3 text-gray-400">
                                ({item?.year})
                              </span>
                            </h3>
                            <div className="flex gap-2">
                              <div className="flex gap-0.5">
                                {Array.from({ length: 5 }).map((item, key) => (
                                  <StarIcon
                                    key={key}
                                    className="size-5"
                                    color={
                                      Math.round(rating_out_of_5) < key + 1
                                        ? "#fff8"
                                        : "gold"
                                    }
                                  />
                                ))}
                              </div>
                              <span className="font-serif font-semibold tracking-wider text-sm">
                                ({rating_out_of_5.toFixed(1)} / 5)
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-wrap gap-2 mb-2 items-start">
                          {String(item?.genre || "")
                            .split(",")
                            .filter((e) => !!e)
                            .map((genre) => (
                              <Link
                                to={`/${item?.Library?.type==='MOVIES'?'movies':'tv-shows'}?genre=${String(genre).trim()}`}
                                key={genre}
                                className="hover:bg-white/30 px-3 pt-1 pb-0.5 rounded-md bg-white/15 border border-white/40 border-dashed text-sm tracking-widest mb-0"
                              >
                                #{String(genre).trim()}
                              </Link>
                            ))}
                        </div>
                        {/* DESCRIPTION */}
                        <p className="mb-4 line-clamp-3 leading-6 text-gray-200 tracking-wide">
                          {item?.overview}
                        </p>
                        {/* BUTTONS */}
                        <motion.div
                          initial="enter"
                          animate={isActive ? "active" : "exit"}
                          variants={variants}
                          className="flex gap-4 flex-wrap "
                        >
                          <Button
                            label="Play"
                            onClick={goToPlay}
                            icon={<PlayIcon className="size-5" />}
                            className="font-bold tracking-widest flex justify-center items-center flex-row-reverse gap-2 px-4 py-2.5 rounded-xl focus:text-black active:scale-95"
                          />
                          <Button
                            label={"Details"}
                            onClick={goToDetails}
                            icon={<InformationCircleIcon className="size-5" />}
                            className="font-bold tracking-widest flex justify-center items-center flex-row-reverse gap-2 px-4 py-2.5 rounded-xl bg-white/20 hover:bg-white/10 text-white  active:scale-95"
                          />
                          <Button
                            icon={<HandThumbUpIcon className="size-5" />}
                            className="rounded-full aspect-square bg-white/10 text-white border-2 border-white/25  active:scale-95"
                          />
                        </motion.div>
                      </div>
                    </div>
                  </div>
                </motion.div>
                {/* END : CONTENT */}
              </div>
            )}
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
});

export default MainSlider;
