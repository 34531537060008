/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
} from "@/components/ui/select";
import { API } from "@/config/api.config";
import { getImagePath } from "@/lib/images";
import {
  cn,
  errorMessage,
  exitFullScreen,
  isFullScreen,
  logError,
  requestFullScreen,
  secondsToTimeFormat,
} from "@/lib/utils";
import { PauseIcon, PlayIcon } from "@heroicons/react/24/solid";
import { Tooltip } from "@material-tailwind/react";
import { useMachine } from "@xstate/react";
import {
  ArrowLeft,
  DownloadCloudIcon,
  MaximizeIcon,
  MinimizeIcon,
  PictureInPicture2Icon,
  RatioIcon,
} from "lucide-react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
const ReactPlayer = React.lazy(() => import("react-player"));
import { Range, getTrackBackground } from "react-range";
import { useNavigate, useSearchParams } from "react-router-dom";
import io from "socket.io-client";
import useSWR from "swr";
import Spinner from "../../components/molicules/Spinner";
import SITE_CONFIG from "../../config/site.config";
import useDevice from "../../hooks/useDevice";
import backward_icon from "./backward.svg";
import {
  AlertModal,
  AlertSchema,
  LoadingVideo,
  PauseScreen,
  PlayerLogo,
  VolumeController,
} from "./components";
import forward_icon from "./forward.svg";
import playerMachine from "./state";
import { LiveTvApi, SERVER_URL } from "../../config/api.config";
import { moviedomVideoUrl } from "../../lib/utils";

/**======================
 **     Fetcher function start
 *========================**/
async function getLiveTV({ id, type }) {
  if (type !== "live") return;
  try {
    const { data } = await LiveTvApi.get(`/channels`);
    const { data:info } = await LiveTvApi.get(`/channel/token/${id}`);
    return {
      ...data?.data?.find((e) => e.id === Number(id)),
      url : info?.data
    };
  } catch (error) {
    throw errorMessage(error);
  }
}

const types = {
  movie: "/movies.php",
  tvseries: "/tvseries.php",
};
async function getPlayerInfo({ id, type }) {
  try {
    if (type === "live" && id) {
      const { data } = await LiveTvApi.get(`/channel/token/${id}`);
      return {
        type: "live",
        url: data?.data,
      };
    }
    // if (!types[type]) throw new Error("invalid type.");
    const { data } = await API.get(`/byid.php`, {
      params: { id: id },
    });
    const result = { ...data[0] };
    if (type === "tvseries") {
      const { data: episodes } = await API.get(`/tvepisodes.php`, {
        params: { tvid: id },
      });
      result.episodes = episodes;
    }
    return result;
  } catch (error) {
    throw errorMessage(error);
  }
}

/**
 * SIGNALS
 */

function Player({ state, send }) {
  //* ----> SEARCH PARAMS START
  const [height, setHeight] = useState("100vh");
  const { isDesktop, isMobile, isTouchable } = useDevice();
  const [searchParams] = useSearchParams();
  const play_id = searchParams?.get("id");
  const episode_id = searchParams?.get("episode");
  const play_type = searchParams?.get("type");
  const play_start_from = searchParams?.get("start") || 0;
  //* ----> SEARCH PARAMS END
  const navigate = useNavigate();
  const [seek_to, setSeekTo] = useState(play_start_from);
  const video_ref = useRef(null);
  const canvas_ref = useRef(null);
  let [count, setCount] = useState(0);
  const [info, setInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [alert_content, setAlert] = useState(null);
  const [pip_mode, setPip] = useState(false);
  //* ------>> API call section
  const {
    data: live_tv,
    isLoading: live_tv_loading,
    error: live_tv_error,
  } = useSWR(
    play_type === "live" ? { id: play_id, type: play_type } : null,
    getLiveTV,
    {
      keepPreviousData: false,
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    }
  );

  //* ------>> GET PLAYER INFO
  const { data: information } = useSWR(
    { id: play_id, type: play_type },
    getPlayerInfo
  );
  const player_data =
    play_type === "movie"
      ? information
      : Array.isArray(information?.episodes)
      ? information?.episodes?.find((e) => e.id === episode_id)
      : null;

  function togglePIP() {
    state.context.playing ? send("PLAY") : send("PAUSE");
    setPip((e) => !e);
  }
  const getPlayer = useCallback(
    function () {
      const plr = video_ref?.current?.getInternalPlayer;
      return plr && plr();
    },
    [video_ref]
  );
  function onSeek(seekTo) {
    if (video_ref?.current?.seekTo) {
      video_ref?.current?.seekTo(seekTo);
    }
  }
  function seekForward10() {
    const val = Math.min(
      Number(state?.context?.duration),
      Number(state?.context?.currentTime) + 10
    );
    if (video_ref?.current?.seekTo) video_ref?.current?.seekTo(val);
  }
  function seekBackward10() {
    const val = Math.max(0, Number(state?.context?.currentTime) - 10);
    if (video_ref?.current?.seekTo) video_ref?.current?.seekTo(val);
  }
  const handlePlayPause = () => {
    try {
      const player = getPlayer();
      if (!player) {
        send("PAUSE");
        return;
      }
      if (state.matches("playing") && player) {
        send("PAUSE");
        player.pause();
      } else {
        send("PLAY");
        player.play();
        // if (play_type === "live" && getPlayer().currentTime)
        //   getPlayer().currentTime = getPlayer().duration;
      }
      send("MOUSE_MOVE");
    } catch (error) {
      logError(error);
    }
  };
  const handleMouseMove = function () {
    setCount(0);
  };
  //* VIDEO CONTROLLER
  useEffect(() => {
    if (isMobile) {
      setHeight(window?.innerHeight);
    }
    window?.addEventListener("resize", () => {
      if (isMobile) {
        setHeight(window?.innerHeight);
      }
    });
    if (document?.body?.oncontextmenu)
      document.body.oncontextmenu = () => false;
    (async function () {
      if (play_type === "live") {
        setLoading(false);
      }
      setLoading(true);
      setError(null);
      try {
        const { data } = await API.get("/stream/video/info", {
          params: {
            id: play_id,
            type: play_type,
          },
        });
        setInfo(data);
      } catch (error) {
        logError(error);
        setError(error);
      } finally {
        setLoading(false);
      }
    })();
    return () => {
      isFullScreen() && exitFullScreen();
      if (document?.body?.oncontextmenu)
        document.body.oncontextmenu = () => true;
    };
  }, [isMobile]);
  useEffect(() => {
    const plr = video_ref?.current?.getInternalPlayer;
    if (plr) {
      if (plr()) plr().style.objectFit = state.context.ratio;
    }
  }, [video_ref, state.context.ratio]);
  useEffect(() => {
    let video = null;
    if (video_ref?.current?.getInternalPlayer) {
      video = video_ref?.current?.getInternalPlayer();
    }
    let canvas = canvas_ref.current;
    function loadedmetadata() {
      // const aspectRatio = video.videoWidth / video.videoHeight;
      // canvas.width = window.innerWidth;
      // canvas.height = window.innerWidth / aspectRatio;
      // draw_in_canvas();
    }
    if (video && canvas) {
      video.addEventListener("loadedmetadata", loadedmetadata);
      return () => {
        video.removeEventListener("loadedmetadata", loadedmetadata, true);
      };
    }
  }, [video_ref, canvas_ref]);
  useEffect(() => {
    const counterInterval = setInterval(() => {
      setCount((e) => e + 1);
    }, 1000);
    const room = io(`${API.getUri()}streaming_users_room`);
    return () => {
      room.disconnect();
      clearInterval(counterInterval);
    };
  }, []);
  // PLAY PAUSE HANDLER
  useEffect(() => {
    const is_idle = state.context.value === "idle";
    const is_playing = state.context.value === "playing";
    const player = getPlayer();
    if (!player) send("PAUSE");
    else {
      is_playing || is_idle
        ? player.play().catch((e) => {
            logError(error);
            console.log("PLAY ERROR", e.message);
            send("PAUSE");
          })
        : player.pause((e) => {
            console.log("Pause ERROR", e.message);
            send("PLAY");
          });
    }
  }, [state.context.value]);

  useEffect(() => {
    setSeekTo(state?.context?.currentTime);
  }, [state?.context?.currentTime]);

  const CONTROLLERS_SHOW_CLASSES =
    count <= 5
      ? "opacity-100 [visibility-visible]"
      : "opacity-0 [visibility-hidden]";

  const download_url =
    play_type === "live"
      ? null
      : moviedomVideoUrl(
          player_data?.MovieWatchLink || player_data?.watchlink || null
        );

  //`${API.getUri()}stream/video/stream?track=${track}&startFrom=${startFrom}&type=${play_type}&id=${play_id}`
  const logo_path =
    play_type === "live"
      ? getImagePath(live_tv?.logo, "live")
      : getImagePath(info?.content_id, info?.logo_type, "logo", 268);
  const poster_path =
    play_type === "live"
      ? getImagePath(live_tv?.logo, "live")
      : getImagePath(info?.content_id, info?.logo_type, "poster", 300);
        console.log({live_tv})
  return (
    <main className={cn("relative")} style={{ height: height }}>
      <Helmet>
        <title> Playing | {SITE_CONFIG.name}</title>
      </Helmet>
      <div
        onMouseMove={handleMouseMove}
        className="absolute top-0 left-0 bg-black h-full w-full"
        id="player"
      >
        <div
          style={{
            backgroundImage: `url(${poster_path})`,
            display: loading ? "flex" : "none",
          }}
          className="z-[10000000000] absolute bg-dark/80 top-0 left-0 h-full w-full flex justify-center items-center bg-repeat-x bg-contain bg-center bg-blend-multiply"
        >
          <Spinner />
        </div>
        <section
          className={cn(
            "bg-gradient-to-b from-black/60 to-transparent fixed top-0 left-0 w-full duration-150 z-[100]",
            CONTROLLERS_SHOW_CLASSES
          )}
        >
          <div className="p-3 flex justify-between items-center">
            <div className="flex flex-grow gap-4">
              <Tooltip
                content="Go Back"
                placement="right"
                className="backdrop-blur-xl backdrop-saturate-150 border-2 border-white/30 bg-white/10 text-white font-semibold tracking-wider"
              >
                <button
                  type="button"
                  className="text-3xl icon-btn bg-white/10"
                  onClick={() => navigate(-1)}
                >
                  <ArrowLeft strokeWidth={2.5} />
                </button>
              </Tooltip>
              {/* <PlayerLogo src={logo_path} alt={info?.title} /> */}
            </div>
            {download_url && SITE_CONFIG.allowDownload ? (
              <Tooltip
                content="Download"
                placement="left"
                className="backdrop-blur-xl backdrop-saturate-150 border-2 border-white/30 bg-white/10 text-white font-semibold tracking-wider"
              >
                <a
                  href={download_url}
                  target="_blank"
                  type="button"
                  className="text-3xl icon-btn bg-white/10"
                  rel="noreferrer"
                >
                  <DownloadCloudIcon strokeWidth={2.5} />{" "}
                </a>
              </Tooltip>
            ) : null}
          </div>
        </section>
        <ReactPlayer
          url={play_type === "live" ? live_tv?.url : download_url}
          muted={state?.context?.muted}
          volume={state?.context?.volume}
          height={"100%"}
          width={"100%"}
          stopOnUnmount
          playsinline
          pip={pip_mode}
          ref={video_ref}
          onDisablePIP={togglePIP}
          onReady={(e) => {
            send({ type: "SET_DURATION", duration: e.getDuration() });
            setAlert(null);
            send("PLAY");
            send({
              type: "SET_LOADING",
              loading: false,
            });
            const player = getPlayer();
            if (player) {
              player.play().catch(() => {
                logError(error);
                send("PAUSE");
              });
            } else {
              console.log("player not found");
            }
          }}
          onPause={() => {
            send("PAUSE");
          }}
          onPlay={() => {
            send("PLAY");
            // draw_in_canvas();
          }}
          onStart={() => {
            send({
              type: "SET_LOADING",
              loading: false,
            });
            setLoading(false);
          }}
          onError={(event) => {
            setError(event?.target?.error?.message);
            send({
              type: "SET_LOADING",
              loading: false,
            });
            // setAlert(
            //   new AlertSchema(null, `Sorry 🥲! Failed to Play Video. `, () =>
            //     navigate(-1)
            //   )
            // );
            logError(event);
          }}
          onProgress={(state) => {
            send({
              type: "SET_CURRENT_TIME",
              currentTime: state.playedSeconds,
            });
          }}
          onBuffer={() => {
            send({
              type: "SET_LOADING",
              value: true,
            });
          }}
          onBufferEnd={() => {
            send({
              type: "SET_LOADING",
              value: false,
            });
          }}
          className={`block video-${state?.context?.ratio}`}
        />
        <section
          className={cn(
            "fixed w-full bottom-0 left-0 z-50 duration-300",
            CONTROLLERS_SHOW_CLASSES
          )}
        >
          <div className="p-3 pb-5 bg-gradient-to-t from-black/70 to-transparent">
            <h3 className="mb-2 text-lg md:text-xl lg:text-2xl xl:text-4xl font-bold border-l-4 border-l-primary ps-2">
              {player_data?.season_number
                ? `S${player_data?.season_number} E${player_data?.episode_number} :`
                : null}{" "}
              {" "}
              {information?.MovieTitle || information?.TVtitle || live_tv?.name}
              {information?.MovieYear || information?.TVrelease
                ? `(${information?.MovieYear || information?.TVrelease})`
                : null}
            </h3>
            {/* <pre className="h-60">{JSON.stringify({ duration: Math.max(1, state?.context?.duration), currentTime: [Number(Math.max(1, state?.context?.currentTime).toFixed(1))] }, null, 5)}</pre> */}
            <div className="mb-2 md:mb-3 lg:mb-4">
              <Range
                step={0.1}
                min={0}
                max={
                  play_type === "live"
                    ? 1
                    : Number(Math.max(1, state?.context?.duration).toFixed(1))
                }
                values={[play_type === "live" ? [1] : [seek_to]]}
                onChange={([value]) => {
                  setSeekTo(value);
                  onSeek(value);
                }}
                // onFinalChange={([value]) => {
                //   setSeekTo(value);
                //   onSeek(value);
                // }}
                renderTrack={({ props, children }) => {
                  return (
                    <div
                      onMouseDown={props.onMouseDown}
                      onTouchStart={props.onTouchStart}
                      style={{
                        ...props.style,
                        height: "20px",
                        display: "flex",
                        width: "100%",
                      }}
                      className="!duration-300 ease-in-out"
                    >
                      <div
                        ref={props.ref}
                        className={cn(
                          "origin-center duration-150 group ease-in-out",
                          play_type === "live"
                            ? "h-[4px]"
                            : "h-[8px] hover:h-[12px]"
                        )}
                        style={{
                          width: "100%",
                          borderRadius: "100px",
                          background: getTrackBackground({
                            values: play_type === "live" ? [1] : [seek_to],
                            colors:
                              play_type === "live"
                                ? ["red", "red"]
                                : ["#fff", "#ffffff30"],
                            min: [0],
                            max:
                              play_type === "live"
                                ? [1]
                                : [state.context.duration],
                          }),
                          alignSelf: "center",
                        }}
                      >
                        {children}
                      </div>
                    </div>
                  );
                }}
                renderThumb={({ props }) => {
                  return (
                    <div
                      {...props}
                      style={{
                        ...props.style,
                      }}
                      className="flex justify-center items-center h-8 w-8 outline-none"
                    >
                      <div
                        className={`${cn(
                          `h-0 w-0 group-hover:h-5 group-hover:w-5 hover:shadow-[0px_0px_0px_5px_#ffffff50] duration-100 ease-in-out rounded-full`,
                          play_type === "live" ? "bg-[red]" : "bg-white"
                        )}`}
                      />
                    </div>
                  );
                }}
              />
              {play_type !== "live" && (
                <div className="flex justify-between items-center">
                  <b className="text-slate-200 text-sm">
                    {secondsToTimeFormat(state?.context?.currentTime || 0)}
                  </b>
                  <b className="text-slate-200 text-sm">
                    {secondsToTimeFormat(state?.context?.duration || 0)}
                  </b>
                </div>
              )}
            </div>
            <div className="flex flex-wrap gap-2 justify-between">
              <div className="flex gap-2 items-center">
                {play_type !== "live" && (
                  <button
                    type="button"
                    className="text-3xl icon-btn group"
                    onClick={seekBackward10}
                  >
                    <img
                      src={backward_icon}
                      className="w-6 h-6 object-contain group-focus:invert"
                    />
                  </button>
                )}
                <button
                  type="button"
                  className="icon-btn"
                  onClick={handlePlayPause}
                >
                  {state.matches("playing") ? (
                    <PauseIcon size={24} strokeWidth={3} className="h-7 w-7" />
                  ) : (
                    <PlayIcon className="text-lg ms-1 h-6 w-6" />
                  )}
                </button>
                {play_type !== "live" && (
                  <button
                    type="button"
                    onClick={seekForward10}
                    className="text-3xl icon-btn group"
                  >
                    <img
                      src={forward_icon}
                      className="w-6 h-6 object-contain  group-focus:invert"
                    />
                  </button>
                )}
                <VolumeController
                  value={[state.context.volume]}
                  mute={state.context.muted}
                  toggleMute={() => {
                    send("TOGGLE_MUTE");
                  }}
                  onchange={([value]) => {
                    send({ type: "SET_VOLUME", volume: value });
                  }}
                />
              </div>
              <div className="flex gap-2 items-center">
                {play_type !== "live" && (
                  <>
                    {/* {Array.isArray(info?.audio) && info?.audio.length > 1 ? (
                      <Select
                        defaultValue={track}
                        value={track}
                        onValueChange={onAudioChange}
                      >
                        <SelectTrigger className="w-auto d-inline border-0 outline-0">
                          <button type="button" className="icon-btn">
                            <MusicalNoteIcon className="h-6 w-6" />
                          </button>
                        </SelectTrigger>
                        <SelectContent className="bg-dark border border-slate-600">
                          {info?.audio?.map((item, key) => (
                            <SelectItem key={key} value={item?.track}>
                              {item?.title}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    ) : null} */}
                    <Select
                      defaultValue="contain"
                      onValueChange={(value) => {
                        send({ type: "SET_RATIO", ratio: value });
                      }}
                    >
                      <SelectTrigger className="w-auto d-inline border-0 outline-0">
                        <button type="button" className="icon-btn">
                          <RatioIcon size={20} strokeWidth={2.5} />
                        </button>
                      </SelectTrigger>
                      <SelectContent className="bg-dark border border-slate-600">
                        <SelectItem className="py-2" value="contain">
                          Auto
                        </SelectItem>
                        <SelectItem className="py-2" value="cover">
                          Cover
                        </SelectItem>
                        <SelectItem className="py-2" value="fill">
                          Fill
                        </SelectItem>
                      </SelectContent>
                    </Select>
                  </>
                )}

                <button
                  type="button"
                  className="icon-btn text-lg"
                  onClick={togglePIP}
                >
                  <PictureInPicture2Icon />
                </button>
                <button
                  type="button"
                  className="icon-btn text-lg"
                  onClick={isFullScreen() ? exitFullScreen : requestFullScreen}
                >
                  {isFullScreen() ? <MinimizeIcon /> : <MaximizeIcon />}
                </button>
              </div>
            </div>
          </div>
        </section>
        <LoadingVideo show={state?.context?.loading} />
        <PauseScreen
          id={info?.content_id}
          show={!state?.context?.loading && !state?.context?.playing}
          getPlayer={getPlayer}
          play_type={play_type}
          data={info}
        />
        <AlertModal
          show={!!alert_content}
          alert={alert_content}
          onClose={() => navigate(-1)}
        />
      </div>
    </main>
  );
}

export default function Play() {
  const [state, send] = useMachine(playerMachine);
  return <Player state={state} send={send} />;
}
