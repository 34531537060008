import PropType from 'prop-types';
import { useState } from "react";
import { IMAGE_TYPE_PROP, TMDB_SIZES, getTMDBimg, image_placeholders } from "../../lib/images";
import { cn } from "../../lib/utils";

const Image = ({
    alt,
    type = IMAGE_TYPE_PROP.VERTICAL,
    src,
    tmdb_src,
    className,
}) => {
    const [img_path, setImgPath] = useState(src);
    const [loading, setLoading] = useState(true);
    let tmdb_poster_size = TMDB_SIZES.poster.sizes.w342;
    let placeholder = image_placeholders.poster;
    if(type===IMAGE_TYPE_PROP.HORIZONTAL) {
        tmdb_poster_size=TMDB_SIZES.backdrops.sizes.w300;
        placeholder = image_placeholders.poster;
    }
    if(type===IMAGE_TYPE_PROP.SQUARE) {
        tmdb_poster_size=TMDB_SIZES.logo.sizes.w300;
        placeholder = image_placeholders.logo;
    }
    return (
        <img
            onError={() => {
                const tmdb_img_path = getTMDBimg(tmdb_src,tmdb_poster_size );
                setImgPath((e) =>
                    e === tmdb_img_path ? placeholder : tmdb_img_path
                );
            }}
            onLoad={() => setLoading(false)}
            loading="lazy"
            placeholder="blur"
            src={img_path || src}
            alt={alt}
            className={cn(
                "w-full object-center  rounded-sm  saturate-150 bg-white/10",
                className||'',
                loading ? 'animate-pulse' : null
            )}
        />
    );
};

Image.prototype = {
    type: PropType.oneOf(Object.values(IMAGE_TYPE_PROP)).isRequired
}

export default Image;