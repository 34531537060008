import BannerPremium from "../components/organisms/banners/banner-premium";
import HomeBannersOne from "../components/organisms/banners/HomeBanner.one";

const SITE_CONFIG = {
  name: "FM FTP",
  logo: "/logo.png",
  logo_class: "w-[160px]",
  allowDownload: true,
  banners: [
    {
      id: 'BANNER_NORMAL_FULL_SCREEN',
      active: 1,
      component: HomeBannersOne
    },
    {
      id: 'BANNER_PREMIUM_FULL_SCREEN',
      active: 0,
      component: BannerPremium
    }
  ]
};

export default SITE_CONFIG;
