
import { twMerge } from "tailwind-merge";

const Button = ({
  label,
  children,
  icon,
  className = "",
  ...props
}) => {
  return (
    <button
      className={twMerge("px-3 py-2 rounded-md bg-white text-dark", className)}
      {...props}
    >
      {icon}
      {label}
      {children}
    </button>
  );
};

export default Button;
