import { ArrowLeftIcon, ArrowRightIcon } from "lucide-react";
import Button from "../../../atoms/Button";
import { Swiper, SwiperSlide } from "swiper/react";
import { getImagePath } from "../../../../lib/images";
import React, { forwardRef } from "react";
import { Autoplay, Navigation, Thumbs, FreeMode } from "swiper/modules";
import { cx } from "class-variance-authority";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/thumbs";
const motion = React.lazy(() => import('framer-motion').then(module => ({ default: module.motion })));

const ThumbSlider = forwardRef(function C(
  { content = [], thumb, onThumbChange },
  ref
) {
  if (!Array.isArray(content) || !content?.length) return null;
  return (
    <motion.div
      initial={{ opacity: 0, y: 400 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{
        duration: 2,
        type: "spring",
      }}
      className="hidden md:block md:absolute bottom-0 right-0 p-3 md:w-1/2 lg:w-3/6 z-30"
    >
      <div className="container flex gap-4 justify-end items-center mb-4">
        <Button
          id="banner-premium-prev"
          label="PREVIOUS"
          className="flex gap-1 tracking-widest py-1.5 focus:text-black items-center  font-semibold text-xs rounded-full disabled:opacity-50 disabled:cursor-not-allowed"
          style={{ boxShadow: "0px 10px 20px 0px #0008" }}
          icon={<ArrowLeftIcon size={16} />}
        />
        <Button
          id="banner-premium-next"
          label="NEXT"
          className="flex gap-1 tracking-widest py-1.5 focus:text-black shadow-xl items-center flex-row-reverse font-semibold text-xs rounded-full disabled:opacity-50 disabled:cursor-not-allowed"
          style={{ boxShadow: "0px 10px 20px 0px #0008" }}
          icon={<ArrowRightIcon size={16} />}
        />
      </div>
      <Swiper
        className="banner-premium-thumb-slider"
        ref={ref}
        breakpoints={{
          0: {
            slidesPerView: 3.2,
          },
          960: {
            slidesPerView: 4.3,
          },
          1260: {
            slidesPerView: 5.3,
          },
        }}
        slidesPerView={5.3}
        spaceBetween={0}
        navigation={{
          nextEl: "#banner-premium-next",
          prevEl: "#banner-premium-prev",
        }}
        autoplay={{
          delay: 4000,
          waitForTransition:true
        }}
        loop
        freeMode
        onSlideChange={(swiper) => {
          console.log(swiper.realIndex);
          onThumbChange(swiper.realIndex);
        }}
        thumbs={thumb ? { swiper: thumb } : undefined}
        watchSlidesProgress={true}
        modules={[Navigation, Thumbs, Autoplay, FreeMode]}
        // onChange={console.log}
        style={
          {
            //   maskImage:
            // "linear-gradient( to right, transparent, black, transparent )",
          }
        }
      >
        {content.map((item) => {
          const poster = getImagePath(
            item.id,
            item?.Library?.type == "MOVIES" ? "movie" : "tv",
            "poster",
            400
          );
          return (
            <SwiperSlide key={item.id}>
              {({ isActive }) => (
                <img
                  src={poster}
                  className={cx(
                    "rounded-lg border-4",
                    isActive ? "border-white/90" : "border-transparent"
                  )}
                />
              )}
            </SwiperSlide>
          );
        })}
      </Swiper>
    </motion.div>
  );
});

export default ThumbSlider;
