import { assign, createMachine } from "xstate";

const playerMachine = createMachine(
  {
    predictableActionArguments:true,
    id: "videoPlayer",
    initial: "idle",
    context: {
      playing: false,
      currentTime: 0,
      duration: 0,
      volume: 1,
      muted: false,
      playbackRate: 1,
      ratio: "contain",
      track: 0,
      fullScreen: false,
      videoUrl: "",
      loading: true,
      show_controller: true,
      show_info: false,
    },
    states: {
      idle: {
        on: {
          PLAY: "playing",
          SET_VIDEO_URL: {
            target: "idle",
            actions: "setVideoUrl",
          },
          SET_DURATION: {
            actions: "setDuration",
          },
          FORWARD: {
            actions: "forward",
          },
          BACKWARD: {
            actions: "backward",
          },
          SET_RATIO: {
            actions: "setRatio",
          },
          SET_LOADING: {
            actions: "setLoading",
          },
          SET_CURRENT_TIME: {
            actions: "setCurrentTime",
          },
        },
      },
      playing: {
        entry: assign({
          playing: true,
        }),
        on: {
          PAUSE: "paused",
          FORWARD: {
            actions: "forward",
          },
          BACKWARD: {
            actions: "backward",
          },
          SET_VOLUME: {
            actions: "setVolume",
          },
          TOGGLE_MUTE: {
            actions: "toggleMute",
          },
          SET_RATIO: {
            actions: "setRatio",
          },
          SET_LANGUAGE: {
            actions: "setLanguage",
          },
          TOGGLE_FULLSCREEN: {
            actions: "toggleFullScreen",
          },
          SET_LOADING: {
            actions: "setLoading",
          },
          SET_DURATION: {
            actions: "setDuration",
          },
          SET_CURRENT_TIME: {
            actions: "setCurrentTime",
          },
          SET_SHOW_CONTROLLER: {
            actions: "setController",
          },
          SET_SHOW_INFO: {
            actions: "setInfo",
          },
        },
      },
      paused: {
        entry: assign({
          playing: false,
        }),
        on: {
          PLAY: "playing",
          SET_DURATION: {
            actions: "setDuration",
          },
          FORWARD: {
            actions: "forward",
          },
          BACKWARD: {
            actions: "backward",
          },
          SET_RATIO: {
            actions: "setRatio",
          },
          SET_LOADING: {
            actions: "setLoading",
          },
          SET_CURRENT_TIME: {
            actions: "setCurrentTime",
          },
        },
      },
    },
  },
  {
    actions: {
      setVideoUrl: assign((context, event) => ({
        ...context,
        videoUrl: event.videoUrl,
      })),
      forward: assign((context) => ({
        ...context,
        currentTime: Math.min(context.currentTime + 10, context.duration),
      })),
      backward: assign((context) => ({
        ...context,
        currentTime: Math.max(context.currentTime - 10, 0),
      })),
      setVolume: assign((context, event) => ({
        ...context,
        volume: event.volume,
      })),
      toggleMute: assign((context) => ({
        ...context,
        muted: !context.muted,
      })),
      setRatio: assign((context, event) => ({
        ...context,
        ratio: event.ratio,
      })),
      setLanguage: assign((context, event) => ({
        ...context,
        language: event.language,
      })),
      toggleFullScreen: assign((context) => ({
        ...context,
        fullScreen: !context.fullScreen,
      })),
      setLoading: assign((context, event) => {
        return ({
          ...context,
          loading: event?.value || false,
        })
      }),
      setDuration: assign((context, event) => ({
        ...context,
        duration: event.duration,
      })),
      setCurrentTime: assign((context, event) => ({
        ...context,
        currentTime: event.currentTime,
      })),
      setController: assign((context, event) => ({
        ...context,
        show_controller: event.show_controller,
      })),
      setInfo: assign((context, event) => ({
        ...context,
        show_info: event.show_info,
      })),
    },
  }
);

export default playerMachine;
