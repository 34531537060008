import { Slider } from "@/components/ui/slider";
import { cn, isTouchDevice } from "@/lib/utils";
import { ArrowLeftIcon, BellAlertIcon, PlayIcon, XMarkIcon } from "@heroicons/react/24/solid";
import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  IconButton
} from "@material-tailwind/react";
import { useMachine } from "@xstate/react";
import { Volume1Icon, Volume2Icon, VolumeIcon, VolumeXIcon } from "lucide-react";
import moment from "moment";
import { memo, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useSWR from "swr";
import Image from "../../components/molicules/Image";
import Spinner from "../../components/molicules/Spinner";
import { API } from "../../config/api.config";
import { getImagePath } from "../../lib/images";
import { errorMessage } from "../../lib/utils";
import playerMachine from "./state";

export class AlertSchema {
  name = null;
  message = null;
  title = null;
  action = () => { };
  constructor(name, message, title, action) {
    if (typeof action === "function") this.action = action;
    this.name = name;
    this.message = message;
    this.title = title;
  }
}

export const LoadingVideo = ({ show }) => (
  <div
    className={cn(
      "z-10 absolute top-0 left-0 h-full w-full flex justify-center items-center bg-dark/50 duration-200",
      show ? "opacity-100" : "opacity-0  !hidden"
    )}
  >
    <Spinner />
  </div>
);

async function fetcher(uri) {
  try {
    const { data } = await API.get(uri, {
      params: {
        fields: 'id,title,tagline,year,release_date,genre,poster_path,backdrop_path'
      }
    });
    return data;
  } catch (error) {
    errorMessage(error);
  }
}

export const PauseScreen = memo(function PauseScreen({ show, play_type, getPlayer, data, id }) {
  let api_end_point = null;
  if (data?.type === 'MOVIES') api_end_point = `/movies/${id}`;
  else if (data?.type === 'TV SERIES') api_end_point = `/tv-shows/${id}`;
  const { data: content_info } = useSWR(api_end_point, fetcher, { keepPreviousData: false, revalidateOnFocus: false, revalidateOnMount: true });
  const handlePlay = () => {
    getPlayer().play();
    if (play_type === "live" && getPlayer().currentTime)
      getPlayer().currentTime = getPlayer().duration;
  };
  if (!api_end_point) return null;
  return (
    <div
      className={cn(
        "absolute top-0 left-0 h-full w-full container md:p-10 flex-col justify-center items-center bg-black/70 bg-blend-multiply bg-cover bg-no-repeat bg-center z-50 duration-300 flex ",
        show ? "opacity-100" : "opacity-0 -z-50"
      )}
      style={{
        backgroundImage: `url(${getImagePath(content_info?.id, 'movie', 'backdrop', 980)}`,
      }}
    >
      <div className="shadow-2xl shadow-black/40 flex items-start gap-2 border-2 border-white/20 bg-white/5 backdrop-blur-sm backdrop-saturate-200 p-2 rounded-md w-full max-w-xl">
        <Image
          src={getImagePath(content_info?.id, 'movie', 'poster', 400)}
          tmdb_src={content_info?.poster_path}
          alt=""
          className="w-16 md:w-28 lg:w-40 xl:w-56 rounded-sm md:rounded-md border-2 border-white/50"
        />
        <div className="flex-grow px-3">
          <h4 className=" tracking-wide">{content_info?.title}</h4>
          {content_info?.tagline ? <p className="font-medium tracking-wide text-gray-400">
            {content_info?.tagline}
          </p> : null}
          <div className="flex gap-1 items-center flex-wrap my-3">
            {(content_info?.Library?.name) && <span className="px-2 py-0.5 rounded-md bg-white/10 text-sm text-white tracking-wider font-medium">
              {content_info?.Library?.name}
            </span>}
            {(content_info?.year || moment(content_info?.release_date).format('YYYY')) && <span className="px-2 py-0.5 rounded-md bg-white/10 text-sm text-white tracking-wider font-medium">
              {content_info?.year || moment(content_info?.release_date).format('YYYY')}
            </span>}
            {String(content_info?.genre).split(',')?.filter(e => !!e)?.map(e => String(e).trim())?.map((item, key) => (
              <Link key={key} to="/" className="px-2 py-0.5 text-sm tracking-wide font-medium rounded-md hover:underline underline-offset-2 hover:border-white/40 border border-white/25">{item}</Link>
            ))}
          </div>
          <div className="flex gap-2 flex-wrap items-center">
            <Button
              className="inline-flex !text-dark text-base items-center gap-2 "
              color="white"
              size="sm"
              variant="gradient"
              onClick={handlePlay}
            >
              <span>Play</span> <PlayIcon className="h-6 w-6 text-dark" />
            </Button>
            {/* <IconButton
              className="inline-flex gap-2 rounded-full"
              color="white"
              variant="gradient"
            >
              <HeartIcon className="h-6 w-6 text-dark" />
            </IconButton> */}
          </div>
        </div>
      </div>
    </div>
  );
});


export function VolumeController({ onchange, value, mute, toggleMute }) {
  const [show, setShow] = useState(false);
  const { 1: send } = useMachine(playerMachine);
  useEffect(() => {
    if (isTouchDevice()) {
      send({ type: "SET_VOLUME", volume: 1 });
    }
  }, [send]);
  if (isTouchDevice()) {
    return null;
  }
  return (
    <div
      className={cn(
        "relative rounded-b-full duration-200",
        show ? "bg-dark" : ""
      )}
      onMouseOverCapture={() => {
        setShow(true);
      }}
      onMouseOutCapture={() => {
        setShow(false);
      }}
    >
      <div
        className={cn(
          "duration-200 w-full bg-dark backdrop-blur-sm backdrop-saturate-150 absolute bottom-full pt-4 rounded-t-full left-0 flex justify-center",
          show
            ? "opacity-1 pointer-events-auto"
            : "opacity-0 pointer-events-none"
        )}
      >
        <Slider
          defaultValue={value}
          max={1}
          min={0}
          step={0.01}
          onValueChange={(val) => {
            if (val[0] > value[0] && !!mute) toggleMute();
            onchange(val);
          }}
          orientation="vertical"
          rangeClassName="w-full"
          activeClassName="bg-white"
          className={cn(
            " duration-200 w-2 bg-white/20 rounded-full",
            show ? "h-24" : "h-0"
          )}
          thumbClassName="cursor-s-resize bg-white border-dark"
        />
      </div>
      <button
        type="button"
        onClick={toggleMute}
        className={cn(
          "icon-btn",
          show
            ? "text-white bg-dark backdrop-blur-0 backdrop-saturate-0"
            : "text-white"
        )}
      >
        {mute ? (
          <VolumeXIcon />
        ) : value[0] >= 0.7 ? (
          <Volume2Icon />
        ) : value[0] >= 0.3 ? (
          <Volume1Icon />
        ) : value[0] > 0 ? (
          <VolumeIcon />
        ) : (
          <VolumeXIcon />
        )}
      </button>
    </div>
  );
}

export function AlertModal({
  show,
  alert = new AlertSchema(),
  onClose = () => { },
}) {
  const navigate = useNavigate();
  return (
    <Dialog open={show} size="sm" className="bg-dark text-white">
      <DialogHeader className="text-white flex gap-2 justify-center items-center">
        <div className="flex items-center flex-grow">
          <BellAlertIcon className="w-7 rotate-45 text-amber-600 h-7 mr-2 inline-block" /> <h5 className="tracking-wider font-[600] flex-grow"> {alert?.title}</h5>
        </div>
        <IconButton
          onClick={onClose}
          className="rounded-full bg-white/10 text-white"
        >
          <XMarkIcon className="h-6 w-6" />
        </IconButton>
      </DialogHeader>
      <DialogBody className="text-gray-300">
        <div className=" p-3 rounded-md bg-black tracking-wide leading-7">
          {alert?.message}
        </div>
      </DialogBody>
      <DialogFooter className="justify-start">
        <Button
          color="orange"
          className="flex gap-2 justify-center items-center"
          onClick={() => navigate("../")}
        >
          <ArrowLeftIcon className="h-5 w-5" /> <span>Go Back</span>
        </Button>
      </DialogFooter>
    </Dialog>
  );
}

export function PlayerLogo({ src, alt }) {
  const [failed, setFailed] = useState(false);
  if (failed) {
    // return alt ? (
    //   <h3 className="mb-2 text-lg md:text-xl lg:text-2xl xl:text-4xl font-bold border-l-4 border-l-primary ps-2">
    //     {alt}
    //   </h3>
    // ) : null;
    return null
  }
  return (
    <img
      src={src}
      alt={alt}
      onError={() => setFailed(true)}
      className="w-full max-w-[100px] max-h-[50px] object-contain object-left"
    />
  );
}

