

import * as SliderPrimitive from "@radix-ui/react-slider";
import * as React from "react";

import { cn } from "@/lib/utils";

const Slider = React.forwardRef(
  ({ className, thumbClassName,trackClassName,activeClassName,rangeClassName, ...props }, ref) => (
    <SliderPrimitive.Root
      ref={ref}
      className={cn(
        "relative flex justify-center items-center w-full touch-none select-none",
        className
      )}
      {...props}
    >
      <SliderPrimitive.Track
        className={cn(
          "relative duration-100 grow overflow-hidden rounded-full bg-secondary cursor-pointer origin-center",
          props.orientation === "vertical" ? "h-full  w-2" : "h-2",trackClassName
        )}
      >
        <SliderPrimitive.Range className={cn("absolute h-full cursor-pointer bg-primary",rangeClassName,activeClassName)} />
      </SliderPrimitive.Track>
      <SliderPrimitive.Thumb
        className={cn(
          "cursor-w-resize block h-4 w-4 hover:h-5 hover:w-5 rounded-full border-2 border-white bg-white ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
          thumbClassName
        )}
      />
    </SliderPrimitive.Root>
  )
);
Slider.displayName = SliderPrimitive.Root.displayName;

export { Slider };

