

import { cn } from "@/lib/utils";
import MaterialSpinner from '@material-tailwind/react/components/Spinner';

export default function Spinner({ size = 50, className, color = "white" }) {
  return (
    <MaterialSpinner
      height={size}
      width={size}
      className={cn("bg-transparent text-white/10", className)}
      color={color}
    />
  );
  // return (
  //   <div
  //     className={cn("custom-spinner bg-white", className)}
  //     style={{ height: size, width: size, "--color": color }}
  //   />
  // );
}
