import { API } from "../../../../config/api.config";
import useSWR from "swr";
import { errorMessage } from "../../../../lib/utils";
import MainSlider from "./main.slider";
import ThumbSlider from "./thumb.slider";
import { useRef } from "react";

async function fetcher() {
  try {
    const { data } = await API.get("/banners?limit=15");
    if (!data?.length) throw new Error("No Data Found");
    return data || [];
  } catch (error) {
    throw errorMessage(error);
  }
}

const BannerPremium = () => {
  const mainSliderRef = useRef(null);
  const thumbSliderRef = useRef(null);
  const { data } = useSWR(`/banner`, fetcher, {
    keepPreviousData: false,
    revalidateOnFocus: false,
  });
  function onThumbChange(index) {
    if (mainSliderRef.current && mainSliderRef.current.swiper) {
      mainSliderRef.current.swiper.slideTo(index);
    }
  }
  function onMainChange(index) {
    if (thumbSliderRef.current && thumbSliderRef.current.swiper) {
      thumbSliderRef.current.swiper.slideTo(index);
    }
  }

  return (
    <div className="relative banner-premium">
      <MainSlider content={data} ref={mainSliderRef} onMainChange={onMainChange} />
      <ThumbSlider content={data} ref={thumbSliderRef} onThumbChange={onThumbChange} />
    </div>
  );
};

export default BannerPremium;
