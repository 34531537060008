import { useLayoutEffect, useState } from "react";
import { logError } from "../lib/utils";

function useDevice() {
  const [isDesktop, setIsDesktop] = useState(true);
  const [isTouchable, setIsTouchable] = useState(false);
  useLayoutEffect(() => {
    try {
      const agent =
        window?.navigator?.userAgent?.toLowerCase &&
        window?.navigator?.userAgent?.toLowerCase();
      const is_desktop =
        !/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini|mobile/i.test(agent);
      setIsDesktop(is_desktop);
    } catch (error) {
      logError(error);
    }
    try {
      const is_touch_device =
        "ontouchstart" in window ||
        navigator?.maxTouchPoints > 0 ||
        navigator?.msMaxTouchPoints > 0;
      setIsTouchable(is_touch_device);
    } catch (error) {
      logError(error);
    }
  }, []);
  return {
    isDesktop,
    isMobile: !isDesktop,
    isTouchable,
  };
}

export default useDevice;
