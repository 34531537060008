/* eslint-disable react/no-unescaped-entities */
import { Button } from "@material-tailwind/react";
import { RotateCcw } from "lucide-react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import SITE_CONFIG from "../../config/site.config";
import { logError } from "../../lib/utils";
import error_image from "./error.svg";
const ErrorPage = ({ error }) => {
  useEffect(() => {
    logError(new Error(error));
  }, [error]);
  function retry() {
    if(window.location.reload) window.location.reload()
  }
  return (
    <main
      className="container min-h-screen py-14 flex justify-center items-center bg-dark/90 bg-blend-multiply bg-cover bg-center"
      style={{
        backgroundImage: `url(/images/bg1.png)`,
      }}
    >
      <Helmet>
        <title> 🚫 Error | {SITE_CONFIG.name}</title>
      </Helmet>
      <div className="flex flex-col items-center">
        <img src={error_image} className="max-w-72 w-full mx-10" />
        <div className="max-w-3xl text-center">
          <h3>
            Oops! <br /> Something went wrong
          </h3>
          <pre className="rounded-md my-2 text-lg text-teal-300 bg-black/10 backdrop-blur-md backdrop-saturate-150 px-4 py-3 shadow-lg shadow-teal-300/20 border-2 border-white/30 whitespace-break-spaces">
            {JSON.stringify(error.message, null, 5)}
          </pre>
          <div className="flex justify-center gap-3 mt-5 max-w-xs mx-auto">
            <Button
              onClick={retry}
              className="bg-white/20 drop-shadow-md border-2 border-white/30 text-white font-semibold backdrop-blur-md backdrop-saturate-150 inline-flex gap-3 items-center justify-around font-body tracking-widest text-[16px]"
            >
              <RotateCcw /> RETRY
            </Button>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ErrorPage;
