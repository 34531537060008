import { API } from "@/config/api.config";
import { errorMessage } from "@/lib/utils";
import {
  InformationCircleIcon,
  PlayIcon,
  StarIcon,
} from "@heroicons/react/24/solid";
import { Tooltip } from "@material-tailwind/react";
import { CalendarDaysIcon, DownloadCloudIcon } from "lucide-react";
import { Link } from "react-router-dom";
import "swiper/css/autoplay";
import "swiper/css/navigation";
import { Autoplay, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import useSWR from "swr";
import { getImagePath, getTMDBimg } from "../../../lib/images";
import SITE_CONFIG from "../../../config/site.config";
import { moviedomVideoUrl } from "../../../lib/utils";

async function fetcher() {
  try {
    const { data } = await API.get("/movies.php?limit=15");
    // const { data: {data:movie_dt} } = await API.get("/movies", {
    //   params: {
    //     sort: "release_date",
    //     sort_by: "DESC",
    //   },
    // });
    // console.log(data,movie_dt);

    if (!data?.length) throw new Error("No Data Found");
    return data || [];
  } catch (error) {
    throw errorMessage(error);
  }
}

const HomeBannersOne = () => {
  const { data } = useSWR(`/banner`, fetcher, {
    keepPreviousData: false,
    revalidateOnFocus: false,
  });

  return (
    <section>
      <Swiper
        className="bg-dark"
        modules={[Navigation, Autoplay]}
        spaceBetween={0}
        navigation={{
          enabled: true,
        }}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        preventClicks
        wrapperClass="items-stretch h-full"
      >
        {data?.map &&
          data.map((item, index) => {
            const parent_route = item?.MovieID ? "movies" : "tv-shows";
            const play_type = item?.MovieID ? "movie" : null;
            const download_url = moviedomVideoUrl(item.MovieID ? item.MovieWatchLink : null);
            if (!parent_route) return null;
            return (
              <SwiperSlide
                key={index}
                style={{
                  backgroundImage: `url(${getTMDBimg(item?.backdrops_Poster,{size:'original'})})`,
                }}
                className="bg-cover bg-center !h-auto"
              >
                <div
                  className=" pt-2 h-full xl:min-h-[560px] xl:h-[85vh] flex flex-col justify-end items-center md:items-start px-8 md:px-16 py-3 md:py-6 lg:py-10 backdrop-blur-xl md:backdrop-blur-none"
                  style={{
                    backgroundImage: `linear-gradient(0deg,#031b23 0,transparent 30%),linear-gradient(40deg,#031b23,transparent 30%)`,
                  }}
                >
                  <img
                    src={getImagePath(
                      "movie",
                      item.MovieID,
                      "poster",
                      item?.poster
                    )}
                    className="w-full max-w-[180px] rounded-md drop-shadow-lg mb-3 md:hidden"
                  />
                  <h3 className="text-xl md:text-2xl tracking-wider leading-[1.3] text-center md:text-left">
                    {item?.MovieTitle}
                  </h3>
                  <div className="mb-2 text-sm flex wrap gap-4 items-center">
                    <span className="inline-flex gap-1 font-semibold text-slate-300 items-center">
                      <StarIcon className=" w-4 h-4 text-yellow-600" />{" "}
                      {item.MovieRatings}
                    </span>
                    {item?.MovieYear ? (
                      <Link
                        to={`/${parent_route}?year=${item?.MovieYear}`}
                        className="font-medium inline-flex gap-1 items-center"
                      >
                        <CalendarDaysIcon
                          size={20}
                          strokeWidth={2.5}
                          className="text-white text-lg"
                        />{" "}
                        {item?.MovieYear}
                      </Link>
                    ) : null}
                    <ul className="flex gap-2 font-medium">
                      {(item?.MovieGenre || "")
                        ?.split(",")
                        ?.map((e) => String(e || "").trim())
                        ?.filter((e) => !!e)
                        ?.map((item, key, array) => (
                          <li key={key} className="inline-flex flex-row">
                            <Link
                              className="underline underline-offset-2 duration-100 text-slate-300 hover:text-primary"
                              to={`/${parent_route}?genre=${item}`}
                            >
                              {item}
                            </Link>{" "}
                            {array.length === key + 1 ? null : ","}
                          </li>
                        ))}
                    </ul>
                  </div>

                  <div className="flex gap-2 flex-row mt-2 mb-3 w-full">
                    {download_url && SITE_CONFIG.allowDownload ? (
                      <Tooltip
                        content="Download"
                        placement="top"
                        className="backdrop-blur-xl backdrop-saturate-150 border-2 border-white/30 bg-white/10 text-white font-semibold tracking-wider"
                      >
                        <a
                          href={download_url}
                          target="_blank"
                          type="button"
                          className="text-3xl icon-btn bg-white hover:bg-white text-dark hover:text-dark"
                          rel="noreferrer"
                        >
                          {" "}
                          <DownloadCloudIcon strokeWidth={2.5} />{" "}
                        </a>
                      </Tooltip>
                    ) : null}
                    <Link
                      to={`/${parent_route}/${item?.MovieID}`}
                      className="btn flex-grow  md:flex-grow-0 rounded-xl w-full md:w-auto"
                    >
                      <InformationCircleIcon className="size-6" /> More
                    </Link>
                    {!!play_type && (
                      <Link
                        to={`/play?type=movie&id=${item?.MovieID}`}
                        className="btn flex-grow  md:flex-grow-0 rounded-xl w-full md:w-auto"
                      >
                        <PlayIcon className="size-6" /> PLAY
                      </Link>
                    )}
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
      </Swiper>
    </section>
  );
};

export default HomeBannersOne;
