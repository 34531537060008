import React from 'react';

const motion = React.lazy(() => import('framer-motion').then(module => ({ default: module.motion })));

const CircleRating = ({ size = 39, color = '#1EDF77', background = '#ffffff10', textColor = "#fff", value = 100, inView = true }) => {
    const radius = (size / 2) - (size * 0.06); // Radius calculated with some padding
    const strokeWidth = size * 0.1; // Stroke width is 10% of the size
    const circumference = 2 * Math.PI * radius;
    const strokeDasharray = `${(value / 100) * circumference}, ${circumference}`;
    const center = size / 2;
    return (
        <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle
                cx={center}
                cy={center}
                r={center}
                fill={background}
            />
            <motion.circle
                initial={{
                    strokeDasharray: 0
                }}
                animate={{
                    strokeDasharray: inView ? strokeDasharray : 0,
                }}
                transition={{
                    duration: 2,
                    delay:0.5,
                    type:'spring'
                }}
                cx={center}
                cy={center}
                r={radius}
                stroke={color}
                strokeWidth={strokeWidth}
                strokeDasharray={strokeDasharray}
                strokeLinecap="round"
                transform={`rotate(-90 ${center} ${center})`}
                style={{ transition: 'stroke-dasharray 0.3s ease' }}
            />
            <text
                x="50%"
                y="50%"
                dominantBaseline="middle"
                textAnchor="middle"
                fontSize={size * 0.4}
                fontWeight='bold'
                letterSpacing={2}
                fill={textColor}
            >
                {String(value || 0).padStart(2, 0)}
            </text>
        </svg>
    );
};

export default CircleRating;
